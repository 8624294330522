// extracted by mini-css-extract-plugin
export var afterCaption = "maine_digest_2021_09-module--afterCaption--23399";
export var bioWrapper = "maine_digest_2021_09-module--bioWrapper--2d502";
export var columnWrapper = "maine_digest_2021_09-module--columnWrapper--2269a";
export var dateline = "maine_digest_2021_09-module--dateline--874e0";
export var heading = "maine_digest_2021_09-module--heading--be880";
export var headingLogo = "maine_digest_2021_09-module--headingLogo--02032";
export var headingWrapper = "maine_digest_2021_09-module--headingWrapper--9b368";
export var heroImage = "maine_digest_2021_09-module--heroImage--e5401";
export var instance = "maine_digest_2021_09-module--instance--ee6d7";
export var latestnewsarticleheadline = "maine_digest_2021_09-module--latestnewsarticleheadline--fbe41";
export var leftBio = "maine_digest_2021_09-module--leftBio--92f7d";
export var maineDigest = "maine_digest_2021_09-module--maineDigest--f8546";
export var photoCaption = "maine_digest_2021_09-module--photoCaption--8ba00";
export var rightBio = "maine_digest_2021_09-module--rightBio--0763e";
export var subheading = "maine_digest_2021_09-module--subheading--05165";
export var textWrapper = "maine_digest_2021_09-module--textWrapper--9ec05";
export var whatsNew = "maine_digest_2021_09-module--whatsNew--9d57b";